/* eslint-disable prettier/prettier */
import { exceptions } from "@/api/exceptions"
import orderService from "@/services/Order.service"
import userService from "@/services/User.service"

import { hideLoading, showLoading } from "@/utils/loading"

class OrderController {
	async createOrder(data) {
		try {
			showLoading()

			const response = await orderService.createOrder(data)

			hideLoading()

			if (response !== null) {
				if (response.status === 201) {
					try {
						userService.myProfile()
					} catch (error) {
						//
					}
					response.status_return = 'success'
				} else {
					response.status_return = 'error'
				}
				return response
			}
		} catch (error) {
			let dados = {}
			dados.status_return = 'error'
			dados.data = exceptions(error);
			hideLoading()
			return dados
		}

		return null
	}
}

export default new OrderController()
